import React from 'react';
import PropTypes from 'prop-types';
import Text from 'lang/Text';
import './Validate.scss';

function Validate(props) {
  const { result } = props;
  const { IsMatch, Score } = result;
  return (
    <div className="validate">
      <div className="validate__title"><Text tid="Result" /></div>
      {IsMatch ? (
        <div>
          <Text tid="Your face data is matching with a score of" />
          {` ${Score} `}
          <Text tid="out of 100" />
        </div>
      ) : (
        <div>
          <Text tid="Your face data is not matching with a score of" />
          {` ${Score} `}
          <Text tid="out of 100" />
        </div>
      )}
    </div>
  );
}

Validate.propTypes = {
  result: PropTypes.shape({
    IsMatch: PropTypes.bool,
    Score: PropTypes.number,
  }),
};

Validate.defaultProps = {
  result: {
    IsMatch: false,
    Score: 0,
  },
};

export default Validate;
