const en = {
  'Upload document front image': 'Upload document front image',
  'Upload your ID Image': 'Upload your ID Image',
  'Step1 -': 'Step1 -',
  'Step2 -': 'Step2 -',
  'Upload recent photo or take a selfie(with Mobile)': 'Upload recent photo or take a selfie(with Mobile)',
  'Your document will be safe and sound.': 'Your document will be safe and sound.',
  'File selected': 'File selected',
  'No file chosen': 'No file chosen',
  'Click verify ,if both images match your request is submitted to business Immediately': 'Click verify ,if both images match your request is submitted to business Immediately',
  'Powered By': 'Powered By',
  'ID-Checkin portal is configured to be Accessed via Registered Apps Only. Please check back Later': 'ID-Checkin portal is configured to be Accessed via Registered Apps Only. Please check back Later',
  Submit: 'Submit',
  'Upload selfie': 'Upload selfie',
  'Make sure image is clear and do not cover any part of the face.': 'Make sure image is clear and do not cover any part of the face.',
  Verify: 'Verify',
  'ID Checkin App - Verifies your ID matches with Live photo': 'ID Checkin App - Verifies your ID matches with Live photo',
  'Welcome to InfoComply ID Verification Portal': 'Welcome to InfoComply ID Verification Portal',
  'Enabling Your Privacy requests with faster response time': 'Enabling Your Privacy requests with faster response time',
  'Vendor id incorrect or does not exist': 'Vendor id incorrect or does not exist',
  'Document image is not recognized': 'Document image is not recognized',
  'Analysing Document': 'Analysing Document',
  'Your face data is matching with a score of': 'Your face data is matching with a score of',
  'Your face data is not matching with a score of': 'Your face data is not matching with a score of',
  'out of 100': 'out of 100',
  Result: 'Result',
};

export default en;
