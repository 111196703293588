import React from 'react';
import PropTypes from 'prop-types';
import Text from 'lang/Text';
import './LandingPage.scss';
import { Image, Button } from 'react-bootstrap';
import AccuantLogo from './acuant-logo.png';

function LandingPage(props) {
  const { message } = props;
  return (
    <div className="landing-page">
      <div className="landing-page__title">
        <Text tid="Welcome to InfoComply ID Verification Portal" />
      </div>
      <div className="landing-page__subtitle">
        <Text tid="Enabling Your Privacy requests with faster response time" />
      </div>
      <div className="landing-page__message">
        <Text tid={message} />
      </div>
      <div style={{
        display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 20,
      }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Text tid="Powered By" />
          <Image src={AccuantLogo} style={{ height: 50, width: 'auto' }} />
        </div>
      </div>
      <Button block onClick={() => window.open('https://idatachoice.com/My-Requests', '_self')} size="lg" variant="primary" style={{ backgroundColor: '#ed3933', marginTop: 30 }}>Go back verify later</Button>
    </div>
  );
}

LandingPage.propTypes = {
  message: PropTypes.string,
};

LandingPage.defaultProps = {
  message: '',
};

export default LandingPage;
