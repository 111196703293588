const tr = {
  'Upload document front image': 'Upload dasdasdocument front image',
  'Upload your ID Image': 'Upload your ID Image',
  'Upload recent photo or take a selfie(with Mobile)':
  'Laden Sie das aktuelle Foto hoch oder machen Sie ein Selfie (mit Mobile)',
  'Step1 -': 'Step1 -',
  'Step2 -': 'Step2 -',
  'Your document will be safe and sound.': 'Yoasdasdu document will be safe and sound.',
  'File selected': 'File seasdasdected',
  'No file chosen': 'No fiasdasdle chosen',
  'Powered By': 'Unterstützt von',
  'ID-Checkin portal is configured to be Accessed via Registered Apps Only. Please check back Later': 'Das ID-Checkin-Portal ist so konfiguriert, dass nur über registrierte Apps darauf zugegriffen werden kann. Bitte versuchen Sie es später erneut',
  Submit: 'Subasdsmit',
  'Upload selfie': 'Uploasdasdad selfie',
  'Make sure image is cleaasdasdr and do not cover any part of the face.': 'Make sure image is clear and do not cover any part of the face.',
  'Click verify ,if both images match your request is submitted to business Immediately': 'Click verify ,if both images match your request is submitted to business Immediately',
  Verify: 'Verify',
  'ID Checkin App - Verifies your ID matches with Live photo': 'ID Checkin App - Verifies your ID matches with Live photo',
  'Welcome to InfoComply ID Verification Portal':
   'Welcome to InfoComply ID Verification Portal',
  'Enabling Your Privacy requests with faster response time': 'Enabling Your Privacy requests with faster response time',
  'Vendor id incorrect or does not exist': 'Vendor id incorrect or does not exist',
  'Document image is not recognized': 'Document image is not recognized',
  'Analysing Document': 'Analysing Document',
  'Your face data is matching with a score of': 'Your face data is matching with a score of',
  'Your face data is not matching with a score of': 'Your face data is not matching with a score of',
  'out of 100': 'out of 100',
  Result: 'Result',
};

export default tr;
// Welcome to infoComply ID Verification Portal
