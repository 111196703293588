import React from 'react';
import ReactDOM from 'react-dom';
import App from 'components/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import * as serviceWorker from './serviceWorker';

const data = {
  vendorId: 1,
  userName: 'prodadmin_infocomply_25eUcV@infocomply.com',
  password: 'C84fVSnhrzFj!n6N',
  subscriptionId: '91d31f95-70d2-4e24-a64e-187c3df50534',
};
// const config = new URLSearchParams(window.location.search);
// const vendorId = config.get('vendorId') || 0;
// console.log('prosssss===>>', process.env.REACT_APP_API);
// fetch(`${process.env.PUBLIC_URL}/data/vendorConfig-${vendorId}.json`)
//   .then((r) => r.json())
//   .then((data) => {
//     console.log('dataaaaaa===>>', data);
//     ReactDOM.render(<App vendorConfigJSON={} />, document.getElementById('root'));
//   });
ReactDOM.render(
  <App vendorConfigJSON={data} />,
  document.getElementById('root'),
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
