import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Image } from 'react-bootstrap';
import Text from 'lang/Text';
import './CaptureSelfie.scss';

function CaptureSelfie(props) {
  const { captureImage, selfieImage, verifySelfieImage } = props;
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    if (!isMounted) {
      document.querySelector('.capture-selfie').scrollIntoView({ behavior: 'smooth' });
      setIsMounted(true);
    }
  }, [isMounted]);
  return (
    <div className="capture-selfie">
      <Form>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>
            {' '}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{
                fontWeight: 'bold', fontSize: 20, width: 100,
              }}
              >
                {'Step 2 - '}
              </div>
              <Text tid="Upload recent photo or take a selfie(with Mobile)" />
            </div>
          </Form.Label>
          <Form.Control type="button" onClick={captureImage} value={selfieImage ? Text({ tid: 'File selected' }) : Text({ tid: 'No file chosen' })} />
          <Form.Text className="text-muted">
            <Text tid="Make sure image is clear and do not cover any part of the face." />
          </Form.Text>
          {selfieImage ? <Image src={selfieImage} thumbnail /> : ''}
        </Form.Group>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{
            fontWeight: 'bold', fontSize: 20, width: 200,
          }}
          >
            {'Step 3 - '}
          </div>
          <Text tid="Click verify ,if both images match your request is submitted to business Immediately" />
        </div>
        <br />
        <Button variant="primary" disabled={!selfieImage} onClick={verifySelfieImage} style={{ marginTop: 10 }}>
          <Text tid="Verify" />
        </Button>
        {/* <div style={{
          display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 20,
        }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text tid="Powered By" />
            <Image src={AccuantLogo} style={{ height: 50, width: 'auto' }} />
          </div>
        </div> */}
      </Form>
    </div>
  );
}

CaptureSelfie.propTypes = {
  selfieImage: PropTypes.string,
  captureImage: PropTypes.func.isRequired,
  verifySelfieImage: PropTypes.func.isRequired,
};

CaptureSelfie.defaultProps = {
  selfieImage: '',
};

export default CaptureSelfie;
