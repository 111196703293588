import React from 'react';
import Text from 'lang/Text';
import './Loading.scss';

const Loading = (props) => {
  const { isLoading, showAnalysingText } = props;
  if (isLoading) {
    return (
      <div className="loading">
        {showAnalysingText ? <div className="lds-text"><Text tid="Analysing Document" /></div> : ''}
        <div className="lds-ring">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    );
  }
  return '';
};

export default Loading;
