import en from './en';
import tr from './tr';

export const dictionaryList = {
  en,
  tr,
};

export const languageOptions = [
  { id: 'en', text: 'English' },
  { id: 'tr', text: 'Turkish' },
];
