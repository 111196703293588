import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ImageVerification from 'containers/ImageVerification';
import { LanguageProvider } from 'lang/LanguageContext';
import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink, concat } from 'apollo-link';
import './App.scss';

function App(props) {
  const { vendorConfigJSON } = props;

  const httpLink = createHttpLink({
    uri: 'https://id-checkin.herokuapp.com/graphql',
  });
  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        Authorization: localStorage.getItem('jwttoken') || null,
      },
    });

    return forward(operation);
  });

  const client = new ApolloClient({
    link: concat(authMiddleware, httpLink),
    cache: new InMemoryCache(),
  });

  return (
    <LanguageProvider>
      <ApolloProvider client={client}>
        <div className="id-proofing-app">
          <Router>
            <Route
              path="/"
              render={(newProps) => (
                <ImageVerification
                  {...newProps}
                  client={client}
                  vendorConfigJSON={vendorConfigJSON}
                />
              )}
            />
          </Router>
        </div>
      </ApolloProvider>
    </LanguageProvider>
  );
}

App.propTypes = {
  vendorConfigJSON: PropTypes.shape({}).isRequired,
};

export default App;
