import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { languageOptions } from 'lang';
import { LanguageContext } from 'lang/LanguageContext';
import Header from 'components/Header';
import CaptureDocument from 'components/CaptureDocument';
import CaptureSelfie from 'components/CaptureSelfie';
import Validate from 'components/Validate';
import LandingPage from 'components/LandingPage';
import Loading from 'components/Loading';
import {
  initializeDocumentAPI,
  uploadFrontImageAPI,
  getClassificationAPI,
  getDocumentResultAPI,
  getFaceImageFromDocumentAPI,
  verifyFaceMatchAPI,
} from 'api';
import gql from 'graphql-tag';
import { BASE_URL } from 'constant';
import './ImageVerification.scss';
import queryString from 'query-string';

export const REQUEST_VERIFICATION = gql`
  query IsUserVerified($appId: String!, $vendorId: String!) {
    verifyRequest(appId: $appId, vendorId: $vendorId) {
      isRequestVerified
    }
  }
`;
export const VERIFY_CUSTOMER_DATA = gql`
 mutation VerifyCustomerData($input : consumerVerifiedDataInput!) {
  consumerVerifiedData(input:$input) {
    callbackUrl
    accessToken
  }
 }
`;


class ImageVerification extends React.Component {
  constructor(props) {
    super(props);
    console.log('propsiget===>>>', props);
    const { location, vendorConfigJSON } = props;
    const { search } = location;
    const config = new URLSearchParams(search);
    const lang = config.get('lang');
    console.log('vendorConfigJSON', vendorConfigJSON);
    const vendorConfig = vendorConfigJSON || {};
    vendorConfig.authToken = btoa(
      `${vendorConfig.userName}:${vendorConfig.password}`,
    );
    this.state = {
      lang,
      ...vendorConfig,
      isLoading: false,
      isUserVerified: false,
    };
  }

  componentDidMount() {
    this.runQuery();
    // this.openInNewTab();
    const { subscriptionId, authToken, lang } = this.state;
    const { location } = this.props;
    console.log('ssssssssss', queryString.parse(location.search));
    const { setLanguage } = this.context;
    const selectedLanguage = languageOptions.find((item) => item.id === lang);
    if (selectedLanguage) {
      // set selected language by calling context method
      setLanguage(selectedLanguage);
    }
    if (subscriptionId && authToken) {
      this.initializeDocumentInstance();
      this.initializeAcuantSDK();
    }
  }

  runQuery = () => {
    /*eslint-disable */
    const { client, location } = this.props;
    const params = queryString.parse(location.search);
    if(params.appId && params.vendorId) {
      client
      .query({
        query: REQUEST_VERIFICATION,
        variables: { appId: params.appId, vendorId: params.vendorId },
      })
      .then((result) => {
        console.log("result========>>", result);
        if (
          result &&
          result.data &&
          result.data.verifyRequest &&
          result.data.verifyRequest.isRequestVerified
        ) {
          this.setState({
            isUserVerified: result.data.verifyRequest.isRequestVerified,
          });
        }
      });
    }
    else {
      this.setState(
        {
          message: 'ID-Checkin portal is configured to be Accessed via Registered Apps Only. Please check back Later',
        },
        () => {
          if (location.pathname !== '/') {
            history.replace({
              pathname: '/',
              search: location.search,
            });
          }
        },
      );
    }
  };

  openInNewTab = (url, token) => {
    window.open(
      `https://idatachoice.com/id-verification/?accessToken=${token}`,
      "_self"
    );
    //  win.focus();
  };

  // helper functions

  toggleLoader = (flag, showAnalysingText) => {
    const { isLoading } = this.state;
    this.setState({
      isLoading: flag || !isLoading,
      showAnalysingText,
    });
  };
  toggleLoaderdummy = (isload) => {
    this.setState({
      isLoading: isload,
    });
  };

  dataURLToBlob = (canvasDataURL) => {
    const binary = atob(canvasDataURL.split(",")[1]);
    const array = [];
    for (let i = 0; i < binary.length; i += 1) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpg" });
  };

  initializeDocumentInstance = () => {
    const { subscriptionId, authToken } = this.state;
    // this.toggleLoader(true);
    initializeDocumentAPI(authToken, subscriptionId)
      .then((response) => {
        console.log("came here!");
        // this.toggleLoader(false);
        if (response.status === 201) {
          this.setState({
            instanceId: response.data,
          });
        }
      })
      .catch((err) => {
        // this.toggleLoader(false);
        console.log("Error in initialize document promise: ", err.message);
      });
  };

  initializeAcuantSDK = () => {
    const { authToken } = this.state;
    const { history, location } = this.props;
    this.toggleLoader(true);
    const this2 = this;
    const tag = document.createElement("script");
    tag.type = "text/javascript";
    tag.async = true;
    document.body.appendChild(tag);
    tag.onload = () => {
      setTimeout(() => {
        window.AcuantJavascriptWebSdk.initialize(authToken, BASE_URL, {
          onSuccess() {
            this2.toggleLoader(false);
            this.isInitialized = true;
            this.isIntializing = false;
            history.replace({
              pathname: "/",
              search: location.search,
            });
          },

          onFail() {
            this2.toggleLoader(false);
            this.isIntializing = false;
          },
        });
      }, 5000);
    };
    tag.src = "/AcuantJavascriptWebSdk.min.js";
  };

  captureDocumentImage = (event) => {
    event.preventDefault();
    this.toggleLoader(true);
    const specialDiv = document.getElementsByClassName("special-div")[0];
    if (specialDiv) {
      specialDiv.className = specialDiv.className.replace(" hidden", "");
    }
    console.log("window==>>", window.AcuantCameraUI);
    window.AcuantCameraUI.start(
      (response) => {
        console.log("came here1!");
        this.toggleLoader(false);
        this.setState(
          {
            message: "",
            documentImage: response.image.data,
          },
          () => {
            window.AcuantCameraUI.end();
            const specialDiv1 = document.getElementsByClassName(
              "special-div"
            )[0];
            if (specialDiv1) {
              specialDiv.className += " hidden";
            }
          }
        );
      },
      (err) => {
        console.log("came here2!");
        this.toggleLoader(false);
        this.setState(
          {
            message: err.message,
          },
          () => {
            window.AcuantCameraUI.end();
            const specialDiv1 = document.getElementsByClassName(
              "special-div"
            )[0];
            if (specialDiv1) {
              specialDiv.className += " hidden";
            }
          }
        );
      }
    );
  };

  captureSelfieImage = (e) => {
    e.preventDefault();
    this.toggleLoader(true);
    window.AcuantPassiveLiveness.startSelfieCapture(
      (image) => {
        this.toggleLoader(false);
        this.setState({
          message: "",
          selfieImage: `data:image/jpeg;base64,${image}`,
        });
      },
      (err) => {
        this.toggleLoader(false);
        this.setState({
          message: err.message,
        });
      }
    );
  };

  handleUploadDocumentImage = (e) => {
    e.preventDefault();
    const { documentImage, instanceId, authToken } = this.state;
    const { history, location } = this.props;

    const imageAsBlob = this.dataURLToBlob(documentImage);
    this.toggleLoader(true, true);
    uploadFrontImageAPI(authToken, instanceId, imageAsBlob)
      .then((response) => {
        if (response.status !== 201) {
          throw new Error("Could not upload front image!");
        } else {
          return getClassificationAPI(authToken, instanceId);
        }
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("firstthen", response.data);
          const result = response.data;
          if (result.Type && result.Type.ClassName === "Unknown") {
            // handle error
            this.setState(
              {
                message: "Document image is not recognized",
                documentImage: "",
              },
              this.initializeDocumentInstance
            );
            throw new Error("Not classified");
          } else {
            // this.getResultsData();
            return getDocumentResultAPI(authToken, instanceId);
          }
        } else {
          throw new Error("No response");
        }
      })
      .then(() => getFaceImageFromDocumentAPI(authToken, instanceId))
      .then((response) => {
        this.toggleLoader(false);
        if (response.status === 200) {
          this.setState(
            {
              // eslint-disable-next-line react/no-unused-state
              message: "",
              resultData: this.processID(response.data),
            },
            () => {
              // const { resultData } = this.state;
              // eslint-disable-next-line react/destructuring-assignment
              console.log("resultdata======>>", this.state.resultData);
              history.replace({
                pathname: "/captureselfie",
                search: location.search,
              });
            }
          );
        }
      })
      .catch((err) => {
        this.toggleLoader(false);
        console.log(
          "Error in handleupload document image promise chain: ",
          err.message
        );
      });
  };

  verifySelfieImage = (e) => {
    e.preventDefault();
    const { authToken, resultData, selfieImage, subscriptionId } = this.state;
    const { history, location } = this.props;
    const { photo } = resultData;
    this.toggleLoaderdummy(true);
    verifyFaceMatchAPI(authToken, photo, selfieImage, subscriptionId)
      .then((response) => {
        this.toggleLoaderdummy(false);
        if (response.status === 200) {
          this.toggleLoaderdummy(false);
          this.setState(
            {
              validationResult: response.data,
            },
            () => {
              this.verifyCustomerData(this.state.validationResult);
              history.replace({
                pathname: "/validate",
                search: location.search,
              });
            }
          );
        }
      })
      .catch((err) => {
        this.toggleLoaderdummy(false);
        console.log("Error in verify selfie promise chain: ", err.message);
      });
  };

  verifyCustomerData = (response) => {
    /*eslint-disable */
    this.toggleLoaderdummy(true);
    const { client, location } = this.props;
    const params = queryString.parse(location.search);
    console.log("parmass==>", params, "response===>>", response);
    const { Score, IsMatch, TransactionId } = response;
    client
      .mutate({
        mutation: VERIFY_CUSTOMER_DATA,
        variables: {
          input: {
            appId: params.appId,
            vendorId: params.vendorId,
            consumerKey: params.consumerKey,
            isMatch: IsMatch,
            transactionId: TransactionId,
            score: Score,
          },
        },
      })
      .then((result) => {
        this.toggleLoaderdummy(false);
        console.log("resultssss=>>>", result);
        if (
          result &&
          result.data &&
          result.data.consumerVerifiedData &&
          result.data.consumerVerifiedData.callbackUrl &&
          result.data.consumerVerifiedData.accessToken
        ) {
          this.openInNewTab(
            result.data.consumerVerifiedData.callbackUrl,
            result.data.consumerVerifiedData.accessToken
          );
        }
      }).catch(error => {
        this.toggleLoaderdummy(false);
      });
  };

  processID = (documentImageData) => {
    const faceImageResultArray = new Uint8Array(documentImageData);
    let rawFaceImage = "";
    let faceImageResultSubArray;
    let base64FaceReformattedImage = null;
    const dataObject = {};
    const chunk = 5000;
    for (let i = 0, j = faceImageResultArray.length; i < j; i += chunk) {
      faceImageResultSubArray = faceImageResultArray.subarray(i, i + chunk);
      rawFaceImage += String.fromCharCode.apply(null, faceImageResultSubArray);
    }
    base64FaceReformattedImage = btoa(rawFaceImage);
    dataObject.photo = `data:image/jpeg;base64,${base64FaceReformattedImage}`;

    return dataObject;
  };

  render() {
    const {
      message,
      documentImage,
      selfieImage,
      isLoading,
      validationResult,
      showAnalysingText,
      resultData,
      isUserVerified,
    } = this.state;
    console.log("thisstatte", isLoading);
    const { photo } = resultData || {};
    return (
      <div className="container-fluid image-verification">
        <Header />
        <div className="row">
          <div className="col-sm-4">
            <LandingPage message={message} />
          </div>
          <Route path="/">
            <div className="col-sm-4">
              {isUserVerified ? (
                <CaptureDocument
                  captureImage={this.captureDocumentImage}
                  documentImage={documentImage}
                  handleUploadDocumentImage={this.handleUploadDocumentImage}
                  disabled={!!photo}
                />
              ) : null}
            </div>
          </Route>
          <Route path="/captureselfie">
            <div className="col-sm-4">
              <CaptureSelfie
                captureImage={this.captureSelfieImage}
                selfieImage={selfieImage}
                verifySelfieImage={this.verifySelfieImage}
              />
            </div>
          </Route>
          <Route path="/validate">
            <div className="col-sm-4">
              <Validate result={validationResult} />
            </div>
          </Route>
        </div>
        <Loading isLoading={isLoading} showAnalysingText={showAnalysingText} />
      </div>
    );
  }
}

ImageVerification.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }),
  vendorConfigJSON: PropTypes.shape({}).isRequired,
};

ImageVerification.defaultProps = {
  history: { replace: () => {} },
};

ImageVerification.contextType = LanguageContext;

export default ImageVerification;
