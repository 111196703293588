import React from 'react';
import Text from 'lang/Text';
import './Header.scss';

const Header = () => (
  <div className="header">
    <div style={{ width: '100%' }}>
      <Text tid="ID Checkin App - Verifies your ID matches with Live photo" />
    </div>
  </div>
);

export default Header;
