import axios from 'axios';
import { BASE_URL, FRM_BASE_URL } from 'constant';

export const documentAPI = axios.create({
  baseURL: BASE_URL,
});

export const faceMatchAPI = axios.create({
  baseURL: FRM_BASE_URL,
});

export const initializeDocumentAPI = (authToken, subscriptionId) => {
  const data = {
    AuthenticationSensitivity: 0,
    ClassificationMode: 0,
    Device: {
      HasContactlessChipReader: false,
      HasMagneticStripeReader: false,
      SerialNumber: 'JavaScriptWebSDK 11.0.0',
      Type: {
        Manufacturer: 'xxx',
        Model: 'xxx',
        SensorType: 3,
      },
    },
    ImageCroppingExpectedSize: 0,
    ImageCroppingMode: 0,
    ManualDocumentType: null,
    ProcessMode: 0,
    SubscriptionId: subscriptionId,
  };
  const options = {
    headers: {
      Authorization: authToken,
    },
  };
  return documentAPI.post('/AssureIDService/Document/Instance', data, options)
    .then((response) => {
      console.log('response.status: ', response.status);
      return response;
    }, (err) => {
      console.log('api error: ', err.message);
      throw err;
    });
};

export const uploadFrontImageAPI = (authToken, instanceId, imageData) => {
  const data = imageData;
  const options = {
    headers: {
      Authorization: authToken,
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  return documentAPI.post(`/AssureIDService/Document/${instanceId}/Image?side=0&light=0&metrics=true`, data, options)
    .then((response) => {
      console.log('response.status: ', response.status);
      return response;
    }, (err) => {
      console.log('api error: ', err.message);
      throw err;
    });
};

export const getClassificationAPI = (authToken, instanceId) => {
  const options = {
    headers: {
      Authorization: authToken,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };
  return documentAPI.get(`/AssureIDService/Document/${instanceId}/Classification`, options)
    .then((response) => {
      console.log('response.status: ', response.status);
      return response;
    }, (err) => {
      console.log('api error: ', err.message);
      throw err;
    });
};

export const getDocumentResultAPI = (authToken, instanceId) => {
  const options = {
    headers: {
      Authorization: authToken,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };
  return documentAPI.get(`/AssureIDService/Document/${instanceId}`, options)
    .then((response) => {
      console.log('response.status: ', response.status);
      return response;
    }, (err) => {
      console.log('api error: ', err.message);
      throw err;
    });
};

export const getFaceImageFromDocumentAPI = (authToken, instanceId) => {
  const options = {
    headers: {
      Authorization: authToken,
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
    responseType: 'arraybuffer',
  };
  return documentAPI.get(`/AssureIDService/Document/${instanceId}/Field/Image?key=Photo`, options)
    .then((response) => {
      console.log('response.status: ', response.status);
      return response;
    }, (err) => {
      console.log('api error: ', err.message);
      throw err;
    });
};

export const verifyFaceMatchAPI = (authToken, imageOne, imageTwo, subscriptionId) => {
  const data = {
    Data: {
      ImageOne: imageOne.split(',')[1],
      ImageTwo: imageTwo.split(',')[1],
    },
    Settings: {
      SubscriptionId: subscriptionId,
    },
  };
  const options = {
    headers: {
      Authorization: authToken,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };
  return faceMatchAPI.post('/api/v1/facematch', data, options)
    .then((response) => {
      console.log('response.status: ', response.status);
      return response;
    }, (err) => {
      console.log('api error: ', err.message);
      throw err;
    });
};

export default {
  initializeDocumentAPI,
  uploadFrontImageAPI,
  getClassificationAPI,
  getDocumentResultAPI,
  getFaceImageFromDocumentAPI,
  verifyFaceMatchAPI,
};
