import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Image } from 'react-bootstrap';
import Text from 'lang/Text';
import './CaptureDocument.scss';

function CaptureDocument(props) {
  const {
    captureImage, documentImage, handleUploadDocumentImage, disabled,
  } = props;
  return (
    <div className={`capture-document ${disabled ? 'disabled' : ''}`}>
      <Form>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div style={{ fontWeight: 'bold', fontSize: 20 }}>{'Step 1 - '}</div>
              <div style={{ marginLeft: 5 }}>
                <Text tid="Upload your ID Image" />
              </div>
            </div>

          </Form.Label>
          <Form.Control type="button" onClick={captureImage} value={documentImage ? Text({ tid: 'File selected' }) : Text({ tid: 'No file chosen' })} />
          <Form.Text className="text-muted">
            <Text tid="Your document will be safe and sound." />
          </Form.Text>
          {documentImage ? <Image src={documentImage} thumbnail /> : ''}
        </Form.Group>
        <Button variant="primary" disabled={!documentImage} onClick={handleUploadDocumentImage}>
          <Text tid="Submit" />
        </Button>
      </Form>
      <div className="special-div">
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video id="acuant-player" controls autoPlay playsInline style={{ display: 'none' }} />
        <div style={{ textAlign: 'center' }}>
          <canvas id="acuant-video-canvas" width="100%" height="auto" />
        </div>
      </div>
    </div>
  );
}

CaptureDocument.propTypes = {
  documentImage: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  captureImage: PropTypes.func.isRequired,
  handleUploadDocumentImage: PropTypes.func.isRequired,
};

CaptureDocument.defaultProps = {
  documentImage: '',
};

export default CaptureDocument;
