import { useContext } from 'react';
import { LanguageContext } from '../LanguageContext';

const Text = (props) => {
  const languageContext = useContext(LanguageContext);
  if (!props.tid) {
    return '';
  }
  if (languageContext.dictionary[props.tid]) {
    return languageContext.dictionary[props.tid];
  }
  return `Phrase ${props.tid} is not defined in ${languageContext.language.text} dictionary`;
};

export default Text;
